<template>
  <div class="basket d-flex flex-column ":class="{'open':mobileBasketState}">
    <div class="basket-header d-flex flex-row justify-between align-center">
      <h5 class="title">My order</h5>
      <h6 class="extra">Add extra</h6>
      <div class="mobile-btn-show-basket" @click="toggleMobileState">
        <img src="@/assets/icons/Arrow-right.svg" alt="">
      </div>
    </div>
    <div class="basket-main">
      <ul class="basket-list">
        <li class="basket-list__item" v-for="item in receiveBasket" :key="item.customId">
          <div class="basket-list__item__content d-flex flex-row ">
            <div class="images">
              <img :src="item.images[0].url" alt="">
            </div>
            <div class="item-description">
              <div class="top-part-description-item">
                <div class="d-flex flex-row justify-between align-center">
                  <h5 class="title">{{ item.title.length >= 15 ? item.title.slice(0,15)+"..." : item.title }}</h5>
                  <div class="d-flex flex-row align-center">
                    <h6 class="price desktop">€ {{ item.price/100 }}</h6>
                    <img @click="deleteItem(item.customId)" class="remove" src="@/assets/icons/Trash.svg" alt="">
                  </div>
                </div>
                <div class="type-item">
                  <h6 class="title">15 roses</h6>
                  <h6 class="price mobile">€ {{ item.price/100 }}</h6>
                </div>
              </div>
              <div class="bottom-part-description-item">
                <ul class="additions-list" v-if="item.tav !== null || item.card !== null || item.gift !==null">
                  <li class="additions-list__item d-flex flex-row justify-between" v-if="item.tav !==null">
                    <div class="d-flex flex-row align-center">
                      <div class="icon-container">
                        <img src="@/assets/img/SelectProduct/User.svg" alt="">
                      </div>
                      <h6 class="title"> {{ item.tav.fname }} {{ item.tav.lname }}</h6>
                    </div>
                    <div class="d-flex flex-row align-center">
                      <h6 class="price">Free</h6>
                      <img @click="deleteTav(item.customId)" class="remove" src="@/assets/icons/Trash.svg" alt="">
                    </div>
                  </li>
                  <li class="additions-list__item d-flex flex-row justify-between" v-if="item.card !==null">
                    <div class="d-flex flex-row align-center">
                      <div class="icon-container">
                        <img src="@/assets/img/SelectProduct/Pen.svg" alt="">
                      </div>
                      <h6 class="title">{{ item.card.title }}</h6>
                    </div>
                    <div class="d-flex flex-row align-center">
                      <h6 class="price">€ {{item.card.price/100}} </h6>
                      <img @click="deletePresentCard(item.customId)" class="remove" src="@/assets/icons/Trash.svg" alt="">
                    </div>
                  </li>
                  <li class="additions-list__item d-flex flex-row justify-between" v-if="item.gift !==null">
                    <div class="d-flex flex-row align-center">
                      <div class="icon-container">
                        <img src="@/assets/img/SelectProduct/gift.svg" alt="">
                      </div>
                      <h6 class="title">{{ '1 gift' }}</h6>
                    </div>
                    <div class="d-flex flex-row align-center">
                      <h6 class="price">{{'From €' + item.gift.price/100 }} </h6>
                      <img @click="deleteGift(item.customId)" class="remove" src="@/assets/icons/Trash.svg" alt="">
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="discount d-flex flex-row justify-between align-center">
        <input class="title" placeholder="Promo code" type="text">
        <h6 class="apply"><img src="@/assets/icons/Arrow-right.svg" alt=""></h6>
      </div>
    </div>
    <div class="basket-footer">
      <ul class="basket-footer-list">
        <li class="basket-footer-list__item">
          <div class="d-flex flex-row justify-between align-center">
            <h6 class="title">Products price</h6>
            <h6 class="price">€ 45,95</h6>
          </div>
        </li>
        <li class="basket-footer-list__item">
          <div class="d-flex flex-row justify-between align-center">
            <h6 class="title">Delivery</h6>
            <h6 class="price">€ 5</h6>
          </div>
        </li>
        <li class="basket-footer-list__item">
          <div class="d-flex flex-row justify-between align-center">
            <h6 class="title">Total</h6>
            <h6 class="price">€ 6</h6>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckoutBasket",
  data(){
    return{
      mobileBasketState:false
    }
  },
  computed:{
    receiveBasket(){
      return this.$store.getters['receiveBasket']
    }
  },
  methods:{
    deleteTav(id){
      this.$store.dispatch('deleteTav',id)
    },
    deletePresentCard(id){
      this.$store.dispatch('deletePresentCard',id)
    },
    deleteGift(id){
      this.$store.dispatch('deleteGift',id)
    },
    deleteItem(id){
      this.$store.dispatch('deleteItem',id)
    },
    toggleMobileState(){
      this.mobileBasketState = !this.mobileBasketState
    }
  },
}
</script>

<style scoped lang="scss">
@import "CheckoutBasket";
</style>